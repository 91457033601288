import React from 'react';

function Loader() {
    return (
        <div className="spinner-container">
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
            <div className="dot dot4"></div>
        </div>
    );
}

export default Loader;
