  import { Navigate } from 'react-router-dom';
  import { Routes, Route } from "react-router-dom"; 
import Loader from "./new-ui/components/Main-Components/loader/loader.jsx";
import React, { Suspense, lazy } from 'react';

  import 'lazysizes';
  import 'bootstrap/dist/css/bootstrap.css';
  import 'bootstrap/dist/js/bootstrap.bundle.js'
  import ScrollToTop from "./components/tamil/Scroll/Scroll";

import "../src/new-ui/components/style/style.css";
import "../src/new-ui/components/style/responsive.css";
import "../src/new-ui/components/style/blog-right.css";
import "../src/new-ui/components/style/blog-view.css";
import "../src/new-ui/components/style/blog.css";

const OnePagePaymentSuccess = lazy(() => import("./new-ui/components/Pages/OnePagePaymentSuccess/OnePagePaymentSuccess.jsx"));
const MarriageMatchingPaymentSuccess = lazy(() => import("./new-ui/components/Pages/MarriageMatchingPaymentSuccess/MarriageMatchingPaymentSuccess.jsx"));
const BiodataPaymentSuccess = lazy(() => import("./new-ui/components/Pages/BiodataPaymentSuccess/BiodataPaymentSuccess.jsx"));
const BabyNamePaymentSuccess = lazy(() => import("./new-ui/components/Pages/BabyNamePaymentSuccess/BabyNamePaymentSuccess.jsx"));
const RuthuJathagaPaymentSuccess = lazy(() => import("./new-ui/components/Pages/RuthuJathagaPaymentSuccess/RuthuJathagaPaymentSuccess.jsx"));
const TamilJathagamBookPaymentSuccess = lazy(() => import("./new-ui/components/Pages/Tamil-jathagam-book-success/TamilJathagamBookSuccess.jsx"));
const AudioPredictionPaymentSuccess = lazy(() => import("./new-ui/components/Pages/AudioPredictionPaymentSuccess/AudioPredictionPaymentSuccess.jsx"));

const BlogList = lazy(() => import("./new-ui/components/Pages/BlogList/BlogList.jsx"));
const BlogFilter = lazy(() => import("./new-ui/components/Pages/BlogFilter/BlogFilter.jsx"));
const Babynameblog = lazy(() => import("./new-ui/components/Pages/BlogView/Babynameblog.jsx"));
const ComputerJadhagam = lazy(() => import("./new-ui/components/Pages/BlogView/ComputerJadhagam.jsx"));
const CreateHoroscopeBlog = lazy(() => import("./new-ui/components/Pages/BlogView/CreateHoroscopeBlog.jsx"));
const HoroscopeBlog = lazy(() => import("./new-ui/components/Pages/BlogView/HoroscopeBlog.jsx"));
const MarriageBioDataBlog = lazy(() => import("./new-ui/components/Pages/BlogView/MarriageBioDataBlog.jsx"));
const MarriageMatchingBlog = lazy(() => import("./new-ui/components/Pages/BlogView/MarriageMatchingBlog.jsx"));
const NakshatraMatchingBlog = lazy(() => import("./new-ui/components/Pages/BlogView/NakshatraMatchingBlog.jsx"));
const NumorologyBlog = lazy(() => import("./new-ui/components/Pages/BlogView/NumorologyBlog.jsx"));
const PanchangamBlog = lazy(() => import("./new-ui/components/Pages/BlogView/PanchangamBlog.jsx"));
const TamilHoroscopeMatchingBlog = lazy(() => import("./new-ui/components/Pages/BlogView/TamilHoroscopeMatchingBlog.jsx"));
const TamilJathagamBlog = lazy(() => import("./new-ui/components/Pages/BlogView/TamilJathagamBlog.jsx"));
const ThirumanaPoruthamBlog = lazy(() => import("./new-ui/components/Pages/BlogView/ThirumanaPoruthamBlog.jsx"));
const LoveCalculatorBlog = lazy(() => import("./new-ui/components/Pages/BlogView/LoveCalculatorBlog.jsx"));
const HoraigalBlog = lazy(() => import('./new-ui/components/Pages/BlogView/HoraigalBlog'));
const JathagaporuthamBlog = lazy(() => import('./new-ui/components/Pages/BlogView/JathagaporuthamBlog'));
const LifeHoroscopeBlog = lazy(() => import('./new-ui/components/Pages/BlogView/LifeHoroscopeBlog'));

const ContactUs = lazy(() => import('./new-ui/components/Pages/ContactUs/ContactUs.jsx'));
const PrivacyPolicyView = lazy(() => import("./new-ui/components/Pages/PrivacyPolicyView/PrivacyPolicyView.jsx"));
const AboutUsView = lazy(() => import("./new-ui/components/Pages/AboutUsView/AboutUsView.jsx"));
const TermConditionsView = lazy(() => import("./new-ui/components/Pages/TermConditionsView/TermConditionsView.jsx"));
const FAQPage = lazy(() => import("./new-ui/components/Pages/FAQPage/FAQPage.jsx"));
const WeeklyRasiPalan = lazy(() => import("./new-ui/components/Pages/WeeklyRasiPalan/WeeklyRasiPalan.jsx"));
const Error404 = lazy(() => import("./new-ui/components/Pages/Error/Error.jsx"));
const CancellationPolicy = lazy(() => import("./new-ui/components/Pages/CancellationPolicy/CancellationPolicy.jsx"));
// admin
const AdminLogin = lazy(() => import('./components/Admin/Login/AdminLogin'));
const AdminReport = lazy(() => import('./components/Admin/Report/Report'));
const Offer = lazy(() => import("./components/Offer/offer"));
const MgOffer = lazy(() => import("./components/Offer/Mgoffer"));
const BabyOffer = lazy(() => import("./components/Offer/BabyOffer"));
const AudioBookPaymentSuccess = lazy(() => import("./components/tamil/payment-success/AudioBookSuccess"));
const MarriageFreePrediction = lazy(() => import("./components/tamil/FreeAudioPredition/MarriageFreePrediction.js"));
const Home = lazy(() => import("./new-ui/components/Pages/Home/Home.jsx"));
const BirthHoroscope = lazy(() => import("./new-ui/components/Pages/Birth-horoscope/BirthHoroscope.jsx"));
const BirthHoroscopeview = lazy(() => import("./new-ui/components/Pages/Birth-horoscope-view/Birth-horoscope-view.jsx"));
const MarriageMatching = lazy(() => import("./new-ui/components/Pages/Marriage-matching/MarriageMatching.jsx"));
const MarriageMatchingView = lazy(() => import("./new-ui/components/Pages/Marriage-Matching-view/MarriageMatchingView.jsx"));
const MarriageBiodata = lazy(() => import("./new-ui/components/Pages/Marriage-Bio-Data/MarriageBioData.jsx"));
const BabyNameList = lazy(() => import("./new-ui/components/Pages/Baby-Name-List/BabyNameList.jsx"));
const BabyNameView = lazy(() => import("./new-ui/components/Pages/Baby-Name-view/BabyNameView.jsx"));
const RuthuJathagamNew = lazy(() => import("./new-ui/components/Pages/Ruthu-Jathagam/RuthuJathagam.jsx"));
const BioDataViewNew = lazy(() => import("./new-ui/components/Pages/Bio-Data-View/BioDataView.jsx"));
const RuthuJathagaView = lazy(() => import("./new-ui/components/Pages/Ruthu-Jathagam-Confirm-Details/RuthuJathagaView.jsx"));
const JathagaPalan = lazy(() => import("./new-ui/components/Pages/Jathaga-Palan/JathagaPalan.jsx"));
const TamilJathagaBook = lazy(() => import("./new-ui/components/Pages/Tamil-jathagam-book/TamilJathagaBook.jsx"));
const TamilJathagaBookView = lazy(() => import("./new-ui/components/Pages/Tamil-Jathagam-Confirm-Details/TamilJathagaBookView.jsx"));
const AstroTalk = lazy(() => import("./new-ui/components/Pages/AstroTalk/AstroTalk.jsx"));


  function App() {
    return (
      <div className="App">
        <ScrollToTop />
        <Suspense fallback={<Loader />}>

        <Routes>
          {/* admin */}
          <Route path="/Admin-Login" element={<AdminLogin />} />
          <Route path="/Admin-Report" element={<AdminReport />} />

          {/* website */}
          <Route path="/offer/:user_id/:data_id/" element={<Offer />} />
          <Route path="/Baby-offer/:user_id/:data_id/" element={<BabyOffer />} />
          <Route path="/Mg-offer/:user_id/:data_id/" element={<MgOffer />} />

          <Route path="/Marriage-free-prediction/:user_id/:mobile_number" element={<MarriageFreePrediction />} />
          <Route path="/Tamil-Jathagam-Payment-Success" element={<AudioBookPaymentSuccess />} />


          <Route path="/index.php" element={<Navigate to="/" />} />
          <Route path="/login.php" element={<Navigate to="/Login" />} />
          <Route path="/birthchart.php" element={<Navigate to="/Birth-horoscope" />} />
          <Route path="/marriagematching.php" element={<Navigate to="/Marriage-matching" />} />
          <Route path="/marriagebiodata.php" element={<Navigate to="/Marriage-Bio-Data" />} />
          <Route path="/babyname.php" element={<Navigate to="/Baby-Name-List" />} />
          <Route path="/contact.php" element={<Navigate to="/contact-us" />} />
          <Route path="/privacy-policy.php" element={<Navigate to="/privacy-policy" />} />
          {/* <Route path="/aboutus.php" element={<Navigate to="/About-us" />} /> */}
          <Route path="/cancellation.php" element={<Navigate to="/Cancellation-policy" />} />
          <Route path="/terms-and-condition.php" element={<Navigate to="/Terms-condition" />} />
          <Route path="/faq.php" element={<Navigate to="/FAQ" />} />
          <Route path="/blog.php" element={<Navigate to="/Blog/categories/Tamil/Blog-Lists" />} />
          <Route path="/blog-details.php" element={<Navigate to="/Blog/categories/Tamil/Blog-View" />} />

          {/* new ui */}
          <Route path="/" element={<Home />} />
          <Route path="/Birth-horoscope" element={<BirthHoroscope />} />
          <Route path="/Birth-Table-view" element={<BirthHoroscopeview />} />
          <Route path="/Marriage-matching" element={<MarriageMatching />} />
          <Route path="/Marriage-Matching-view" element={<MarriageMatchingView />} />
          <Route path="/Marriage-Bio-Data" element={<MarriageBiodata />} />
          <Route path="/Bio-Data-View" element={<BioDataViewNew />} />
          <Route path="/Baby-Name-List" element={<BabyNameList />} />
          <Route path="/Baby-Name-view" element={<BabyNameView />} />
          <Route path="/Ruthu-Jathagam" element={<RuthuJathagamNew />} />
          <Route path="/Ruthu-Jathagam-Confirm-Details" element={<RuthuJathagaView />} />
          <Route path="/Jathaga-Palan" element={<JathagaPalan />} />
          <Route path="/Tamil-jathagam-book" element={<TamilJathagaBook />} />
          <Route path="/Tamil-Jathagam-Confirm-Details" element={<TamilJathagaBookView />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
          <Route path="/About-us" element={<AboutUsView />} />
          <Route path="/Terms-condition" element={<TermConditionsView />} />
          <Route path="/Talk-To-Astrologer" element={<AstroTalk />} />
          <Route path="/Cancellation-policy" element={<CancellationPolicy />} />
          <Route path="/Blog" element={<BlogList />} />
          <Route path="/Blog/category/:category_name/" element={<BlogFilter />} />
          <Route path="/FAQ" element={<FAQPage />} />
          <Route path="/rasi-palan/Weekly-rasi-palan" element={<WeeklyRasiPalan />} />
          <Route path="/Blog/New-Born-Baby-Jathagam-in-Tamil" element={<Babynameblog />} />
          <Route path="/computer-Jathagam" element={<ComputerJadhagam />} />
          <Route path="/Blog/Jathagam-Online-PDF" element={<CreateHoroscopeBlog />} />
          <Route path="/Horaigal" element={<HoraigalBlog />} />
          <Route path="/Blog/tamil-astrology-12-houses-explained" element={<HoroscopeBlog />} />
          <Route path="/Blog/importance-of-Jathagam-Porutham" element={<JathagaporuthamBlog />} />
          <Route path="/Blog/Life-Horoscope-In-tamil" element={<LifeHoroscopeBlog />} />
          <Route path="/Blog/bio-data-for-marriage-in-tamil" element={<MarriageBioDataBlog />} />
          <Route path="/Blog/marriage-matching-tips" element={<MarriageMatchingBlog />} />
          <Route path="/Blog/nakshatra-matching" element={<NakshatraMatchingBlog />} />
          <Route path="/Blog/Numerology-Calculator" element={<NumorologyBlog />} />
          <Route path="/Today-tamil-Panchangam" element={<PanchangamBlog />} />
          <Route path="/Blog/tamil-horoscope-matching" element={<TamilHoroscopeMatchingBlog />} />
          <Route path="/Blog/important-content-to-verify-in-tamil-jathagam" element={<TamilJathagamBlog />} />
          <Route path="/Blog/Guide-to-Thirumana-porutham" element={<ThirumanaPoruthamBlog />} />
          <Route path="/Love-Calculator" element={<LoveCalculatorBlog />} />
          <Route path="/OnePage-ThankYou" element={<OnePagePaymentSuccess />} />
          <Route path="/Marriage-Matching-ThankYou" element={<MarriageMatchingPaymentSuccess />} />
          <Route path="/BioData-ThankYou" element={<BiodataPaymentSuccess />} />
          <Route path="/BabyName-ThankYou" element={<BabyNamePaymentSuccess />} />
          <Route path="/RuthuJathagam-ThankYou" element={<RuthuJathagaPaymentSuccess />} />
          <Route path="/Horosocope-Book-ThankYou" element={<TamilJathagamBookPaymentSuccess />} />
          <Route path="/Audio-Prediction-ThankYou" element={<AudioPredictionPaymentSuccess />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        </Suspense>
      </div>
    );
  }

  export default App;